<template>
  <div class="grid grid-cols-4 content-center">
    <div class="place-self-center">
      <label class="label">{{ label }}</label>
    </div>
    <div>
      <input :type="type" :placeholder="placeholder" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseInput',
  props: {
    label: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
}
</script>
<style scoped>
label {
  font-size: 16px;
  font-weight: 600;
  color: #232323;
  font-family: 'Lato';
}
input[type='text'],
input[type='password'] {
  background-color: #fdfdfd;
  height: 38px;
  border: 1px solid #9e9e9e;
  border-radius: 8px;
  width: 328px;
  padding: 8px;
  color: #232323;
}
input[type='text']:focus,
input[type='password']:focus {
  outline: none;
}
</style>
  
