<template>
  <div class="bg-white min-h-screen">
    <div class="container mx-auto h-screen flex justify-center items-center">
      <div class="w-[500px]">
        <h1 class="font-[Lora] text-[28px] font-bold mb-7 text-center">
          BackOffice
        </h1>
        <Input label="Username" type="text" placeholder="Username" />
        <div class="mt-4">
          <Input label="Password" type="password" placeholder="Password" />
        </div>
        <div class="text-center mt-5">
          <Button name="Login" height="h-[38px]" width="w-[152px]" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Base/Button'
import Input from '@/components/Base/Input'

export default {
  name: 'LoginPage',
  components: {
    Button,
    Input,
  },
}
</script>

