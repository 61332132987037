<template>
  <button :class="style">
    {{ name }}
  </button>
</template>
<script>
export default {
  name: 'ButtonBase',
  props: {
    name: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: 'h-[30px]',
    },
    width: {
      type: String,
      default: 'w-[30px]',
    },
  },
  data() {
    return {
      style: `
      border 
      bg-[#069550] 
      text-white 
      rounded 
      text-[14px] 
      font-[Lato] 
      font-semibold
      ${this.height} 
      ${this.width}`,
    }
  },
}
</script>



